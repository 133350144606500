<template>
  <CRow>
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{ $t('todo.Todo_categories') }}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="searchEnabled" size="is-small">{{ $t('common.activate_search') }}</b-switch>
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-2 link" color="primary" @click="newTodoListCategoryModal = true; resetTodoListCategoryData();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('todo.Add_category') }}</span>
                </CButton>
              </div> 
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <b-table class="data_table" :data="todoListCategories" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                <template slot-scope="props">
                  <b-table-column field="todo_list_category_name" :label="$t('Category')" :searchable="searchEnabled" width="100%">
                    <quick-edit v-if="props.row.default_category === 'N'" type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('cancel')" v-model="props.row.todo_list_category_name" v-on:input="updateTodoListCategory(props.row.todo_list_category_id, props.row)">
                      <span>{{props.row.todo_list_category_name}}<i class="icon edit fas fa-pen ml-1"/></span>
                      <template slot="button-ok"><i class="fas fa-check"/></template>
                      <template slot="button-cancel"><i class="fas fa-times"/></template> 
                    </quick-edit>
                    <span v-else>{{props.row.todo_list_category_name}}</span>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('todo.Loading_categories')"/>
                    <span v-else>{{ $t('common.no_categories_found') }}</span>
                  </div>                
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  
    <b-modal class="todo_list" :can-cancel="[]" :active.sync="newTodoListCategoryModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{ $t('todo.New_category') }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CInput type="text" :label="$t('todo.Category_name')" class="mb-0" v-model="newTodoListCategory.todo_list_category_name" required was-validated/>
            </CCol>            
          </CRow>            
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="insertTodoListCategory();"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="newTodoListCategoryModal = false; resetTodoListCategoryData();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>            
        </CCardFooter>          
      </CCard>
    </b-modal>
  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'TodoListCategories',
  components: {
    QuickEdit,
    loadingSpinner
  },
  data() {
    return {
      todoListCategories: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      searchEnabled: false,
      newTodoListCategory: {},
      newTodoListCategoryModal: false
    }
  },
  methods: {
    insertTodoListCategory () {
      let params = {};
      params.todo_list_category_name = this.newTodoListCategory.todo_list_category_name;
      params.language_id = 2;

      let todo_list_category_name = params.todo_list_category_name;      

      if(todo_list_category_name) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/hub/todo/categories', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Category_added'), type: 'is-success', duration: 2000 });
          this.getTodoListCategories();
          this.resetTodoListCategoryData();
          this.newTodoListCategoryModal = false;
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateTodoListCategory (id, data) {
      let todoListCategory = id;
      let params = {}
      params = data;
      params.language_id = 2;
            
      axios.put(process.env.VUE_APP_API_URL + '/v1/hub/todo/category/' + todoListCategory, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Category_updated'), type: 'is-success', duration: 2000 });
        this.getTodoListCategories();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    getTodoListCategories () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/categories')
      .then(res => {      
        this.todoListCategories = res.data.data;
        this.overviewDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetTodoListCategoryData () {
      this.newTodoListCategory = {
        todo_list_category_name: ''
      }
    },
  },
  mounted: function(){
    this.getTodoListCategories();
    this.resetTodoListCategoryData();
  }
}
</script>
